button {
  position: relative;
  width: 100%;
  margin: 40px 0 30px;
  padding: 6px;
  border-radius: 6px;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  font-weight: bold;
  background-color: rgb(255, 102, 0);
  transition: all 0.1s;
  cursor: pointer;

  &:hover {
    transform: translate(2px, -1px);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translate(-1px, 1px);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
      2px 2px 6px rgba(0, 0, 0, 0.3) inset;
  }

  &.btn_lista {
    position: absolute;
    top: 2.9rem;
    left: 0;
    width: min-content;
    margin: 0;
    padding: 6px 18px 6px 12px;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9rem;
    white-space: nowrap;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 102, 0);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.1s;

    &:hover {
      transform: translate(2px, -1px);
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
    }

    &:active {
      transform: translate(-1px, 1px);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
        2px 2px 6px rgba(0, 0, 0, 0.3) inset;
    }

    svg {
      position: absolute;
      top: -2px;
      right: -6px;
      width: 32px;
      height: 32px;
    }
  }

  &.btn_reset_lista {
    position: absolute;
    top: 16px;
    right: 408px;
    width: min-content;
    margin: 0;
    padding: 4px 18px;
    font-size: 0.9rem;
    white-space: nowrap;
  }

  &.btn_icon {
    width: min-content;
    height: min-content;
    font-size: 1rem;
    padding: 8px 18px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &.btn_link {
    width: min-content;
    position: relative;
    display: block;
    margin: 12px 6px 0;
    padding: 8px 36px 8px 18px;
    font-weight: bold;
    font-size: 0.9rem;
    border-radius: 12px;
    color: rgb(70, 70, 70);
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    transition: all 0.1s;
    cursor: pointer;
    border: 1px solid #464646;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      transform: translate(2px, -1px);
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
    }

    &:active {
      transform: translate(-1px, 1px);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
        2px 2px 6px rgba(0, 0, 0, 0.3) inset;
    }

    svg {
      position: absolute;
      top: 4px;
      right: 6px;
      width: 24px;
      height: 24px;
    }
  }

  &.download-button {
    width: min-content;
    white-space: nowrap;
    padding: 6px 32px;
    margin: 0 auto;

    svg {
      width: 22px;
      height: 22px;
      margin-right: 8px;
      margin-bottom: -4px;
    }

    &._desactivado {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.titulo_msg {
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(60, 60, 60);
  text-align: center;
  white-space: pre-line;
  margin-bottom: 20px;
}

.form_principal {
  width: 100%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;

  p {
    &.rerumen {
      display: block;
      width: 100%;
      margin: 0 12px;
      padding: 8px 16px 8px 0;
      font-size: 0.8rem;
      font-weight: bold;
      color: rgb(13, 63, 0);
      border-bottom: 1px solid rgb(216, 216, 216);

      span {
        display: inline-block;
        margin: 0;
        padding: 0 8px 0 4px;
        font-size: 0.9rem;
        color: rgb(149, 149, 149);
      }
    }

    &.rerumen_textarea {
      width: 100%;
      margin: 0 12px;
      padding: 12px 8px;
      white-space: pre-line;
      border: 1px solid #d8d8d8;
      border-radius: 6px;
    }

    &.detalle {
      display: block;
      width: 100%;
      padding: 8px 16px 8px 34px;
      font-size: 0.8rem;
      font-weight: bold;
      color: rgb(13, 63, 0);
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(216, 216, 216);
      border-radius: 6px;
    }

    &._to_p {
      width: 100%;
      padding: 8px 16px;
      font-size: 1rem;
      border-bottom: 1px solid rgb(216, 216, 216);
    }
  }

  h2 {
    width: 100%;
    text-align: center;
    margin: 20px 0 40px;
    font-size: 1.3rem;
    color: rgb(60, 60, 60);

    &.title_close {
      position: relative;
      margin: 0 0 40px;
      padding: 0;

      .close {
        position: absolute;
        top: 0;
        right: -26px;
        width: 26px;
        height: 26px;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 576px) {

      &.title_close {
        margin: 0 0 20px;
        padding: 0 30px 0 0;

        .close {
          right: 4px;
        }
      }
    }
  }

  label {
    position: relative;
    display: block;
    margin: 16px 0 0;
    padding: 0 16px;
    font-size: 1rem;
    color: rgb(149, 149, 149);

    &.label_checkbox {
      width: min-content;
      white-space: nowrap;
      color: rgb(70, 70, 70);
      font-size: 0.9rem;
      cursor: pointer;

      input {
        margin: 0 12px 0 0;
        padding: 0;
        width: 14px;
        height: 14px;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .close {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }

  input {
    width: 100%;
    padding: 8px 16px;
    font-size: 0.8rem;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 6px;

    &::placeholder {
      color: rgb(190, 190, 190);
    }

    &[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  select {
    width: 100%;
    padding: 8px 16px;
    font-size: 0.8rem;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 6px;
  }

  textarea {
    width: 100%;
    height: 120px;
    padding: 8px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    resize: none;
    font-size: 0.8rem;

    &::placeholder {
      color: rgb(149, 149, 149);
    }
  }

  fieldset {
    display: flex;
    flex-direction: column;
    width: calc(100% + 40px);
    margin: 20px 0 0 -20px;
    padding: 8px 12px;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 6px;

    legend {
      font-size: 0.9rem;
      margin-left: 8px;
      color: rgb(149, 149, 149);
      font-weight: bold;
      font-style: italic;
    }

    h2 {
      margin: 8px 0;
      padding: 0 0 0 12px;
      font-size: 1rem;
      line-height: 1.2;
      text-align: left;
      color: rgb(80, 80, 80);
    }

    p {
      margin: 18px 12px 8px;
      font-size: 0.8rem;
      font-style: italic;
      color: rgb(165, 0, 0);

      &._info {
        margin: 0;
        padding: 0 0 0 12px;
        line-height: 1.3;
        color: rgb(80, 80, 80);
      }
    }
  }

  span {
    margin: 0 auto;

    &.download-button {
      width: min-content;
      white-space: nowrap;
      padding: 6px 32px;
      margin: 0 auto;

      svg {
        width: 22px;
        height: 22px;
        margin-right: 8px;
        margin-bottom: -4px;
      }
    }
  }

  .fila {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 0 8px;

    .columna:nth-child(2):not(.not_i) {
      margin-right: 0;
      margin-left: 24px;
    }

    .columna:nth-child(3):not(.not_i) {
      width: 150%;
      margin-right: 0;
      margin-left: 24px;
    }
  }

  .columna {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.info_sell {
      display: block;

      h1 {
        font-size: 1.5rem;
        font-weight: bold;
        color: rgb(60, 60, 60);
        margin: 18px 0 22px;
        text-align: left;

        span {
          font-size: 1.5rem;
          color: rgb(90, 90, 90);
          margin: 0 0 0 10px;
        }
      }

      p {
        font-size: 1rem;
        line-height: 1.5;
        font-style: italic;
        color: rgb(100, 100, 100);
        margin: 32px 0 28px;
        text-align: left;
      }

      .item_sell {
        padding-bottom: 12px;
        border-bottom: 1px solid rgb(216, 216, 216);

        h2 {
          font-size: 1.1rem;
          font-weight: bold;
          color: rgb(60, 60, 60);
          margin: 16px 0 6px;
          text-align: left;
        }

        h3 {
          font-size: 0.9rem;
          font-weight: normal;
          color: rgb(60, 60, 60);
          margin: 0 0 8px;
          text-align: left;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          span {
            font-size: 0.8rem;
            font-weight: bold;
            color: rgb(100, 100, 100);
          }

          i {
            font-size: 1.2rem;
            font-weight: bold;
            color: rgb(70, 70, 70);
            margin: 0 0 0 12px;

            svg {
              width: 18px;
              height: 18px;
              margin: 0 12px -2px;
              border: 1px solid rgb(70, 70, 70);
              border-radius: 4px;
              cursor: pointer;
              transition: all 0.1s;

              &:active {
                transform: translate(-2px, 2px);
              }
            }
          }
        }

        p {
          font-size: 0.9rem;
          line-height: 1.2;
          font-style: italic;
          color: rgb(100, 100, 100);
          margin: 16px 0 0;
          text-align: left;

          samp {
            float: right;
            padding: 4px 12px;
            margin: 0 0 0 12px;
            font-size: 0.7rem;
            font-weight: bold;
            color: rgb(255, 255, 255);
            background-color: rgb(21, 90, 0);
            border-radius: 12px;
          }
        }
      }
    }
  }

  .input_icon {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    p,
    input,
    select {
      margin: 0;
      padding: 8px 16px 8px 34px;
    }

    svg {
      position: absolute;
      top: auto;
      left: 6px;
      width: 21px;
      height: 21px;
      color: rgb(255, 102, 0);

      &.btn_close {
        top: auto;
        left: auto;
        right: 6px;
        cursor: pointer;
      }
    }

    ._float_list {
      position: absolute;
      top: 30px;
      width: 100%;
      max-height: 150px;
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
      overflow: auto;
      pointer-events: all;
      scrollbar-color: rgb(160, 160, 160) rgba(0, 0, 0, 0);
      scrollbar-width: 10px;
      background-color: rgb(240, 240, 240);
      border: 1px solid rgb(190, 190, 190);
      z-index: 90;

      &._on_top {
        top: auto;
        bottom: 30px;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0);
      }

      &::-webkit-scrollbar-thumb {
        background: rgb(160, 160, 160);
        border-radius: 10px;
      }

      li {
        width: 100%;
        min-height: 24px;
        display: block;
        padding: 6px 6px;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        line-height: 1;
        text-align: left;
        white-space: nowrap;
        background: none;
        border: none;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        color: rgb(29, 29, 29);

        &._selected {
          background-color: lighten(rgb(50, 93, 208), 30%);

          &:hover {
            background-color: darken(rgb(240, 240, 240), 10%);
          }
        }

        &:hover {
          background-color: darken(rgb(240, 240, 240), 10%);
        }
      }
    }
  }

  .contrasenia {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-bottom: 10px;

    input {
      margin: 0;
      padding: 8px 16px 8px 34px;
    }

    svg {
      position: absolute;
      top: auto;
      left: 6px;
      width: 21px;
      height: 21px;
      color: rgb(255, 102, 0);

      &.eye_icon {
        right: 6px;
        left: auto;
        cursor: pointer;
      }
    }
  }

  .adjuntar {
    position: relative;
    width: 100%;
    height: 120px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;

    input[type="file"] {
      display: none;
    }

    .input_message {
      width: 100%;
      height: 100%;
      padding: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      svg {
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: 12px;
        color: rgb(120, 120, 120);
      }

      p {
        margin: 0;
        font-size: 0.8rem;
        color: rgb(120, 120, 120);
      }

      &:hover {
        background-image: linear-gradient(
          -45deg,
          rgb(200, 200, 200) 25%,
          transparent 25%,
          transparent 50%,
          rgb(200, 200, 200) 50%,
          rgb(200, 200, 200) 75%,
          transparent 75%,
          transparent
        );

        background-size: 30px 30px;
        animation: striped 2s linear infinite;

        @keyframes striped {
          from {
            background-position: 0 0;
          }

          to {
            background-position: 60px 30px;
          }
        }
      }
    }

    .aside_content {
      width: 100%;
      height: 120px;
      text-align: center;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: 0;
      }

      .aside_image_name {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 2px 8px;
        font-size: 0.8rem;
        color: rgb(255, 255, 255);
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 6px 6px 0 0;
      }

      .aside_image_button {
        position: absolute;
        top: 8px;
        right: 8px;
        width: min-content;
        height: min-content;
        padding: 4px 8px;
        margin: 0;
        font-size: 0.7rem;
      }
    }

    .aside_file_name {
      display: block;
      width: 100%;
      margin: 6px 0;
      padding: 8px 4px 8px 8px;
      font-size: 0.7rem;
      line-height: 1;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      position: relative;

      .aside_file_button {
        position: absolute;
        top: 0;
        right: 6px;
        width: min-content;
        height: min-content;
        padding: 4px 8px;
        margin: 0;
        font-size: 0.7rem;
      }
    }
  }

  .items_agregados {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin: 20px 0;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20xp;

      color: rgb(255, 102, 0);
      cursor: pointer;
      transition: all 400ms;

      &:active {
        transform: scale(0.8);
      }

      &.arrow {
        right: auto;
        left: 0;
      }
    }

    p {
      position: relative;
      text-align: left;
      padding: 4px 22px 4px;

      span {
        font-size: 0.8rem;
        font-weight: bold;
        color: rgb(90, 90, 90);
      }
    }
  }

  .cont_to_rigth {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    a {
      width: min-content;
      font-size: 0.8rem;
      white-space: nowrap;
    }
  }

  .registrate {
    width: 100%;

    p {
      width: 100%;
      margin-bottom: 40px;
      font-size: 0.8rem;
      color: rgb(117, 117, 117);
    }

    a {
      color: rgb(255, 102, 0);
      font-weight: bold;
      text-decoration: none;
      font-size: 0.9rem;
    }
  }

  .img_plantilla {
    width: 200px;
    height: 150px;
    object-fit: contain;
    margin: 0;
  }

  @media screen and (max-width: 576px) {
    padding: 0;
  }
}

.select_opt {
  width: 100%;
  display: block;

  h2 {
    display: block;
    padding: 12px 16px;
    margin-bottom: 10px;
    font-size: 1.3rem;
    color: rgb(60, 60, 60);

    &.title_close {
      position: relative;
      width: 100%;
      text-align: center;
      margin: 0 0 40px;
      padding: 0;

      .close {
        position: absolute;
        top: 0;
        right: 4px;
        width: 26px;
        height: 26px;
        cursor: pointer;
      }

      @media screen and (max-width: 576px) {
        margin: 0 0 30px;
        padding: 0 30px 0 0;
      }
    }
  }

  p {
    display: block;
    padding: 0 16px;
    margin-bottom: 32px;
    font-size: 0.9rem;
    color: rgb(90, 90, 90);
  }

  .cont_select_opt {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 860px;
  }

  .select_opt_item {
    position: relative;
    width: 50%;
    min-width: 150px;
    max-width: 400px;
    display: block;
    margin: 18px 12px;
    padding: 12px 0 12px 62px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    cursor: pointer;
    transition: all 100ms;

    &:hover {
      transform: translate(2px, -1px);
      box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.4);
    }

    &:active {
      transform: translate(-1px, 1px);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
        2px 2px 6px rgba(0, 0, 0, 0.3) inset;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 18px;
      width: 26px;
      height: 26px;
      color: rgb(255, 102, 0);
      transform: translateY(-50%);
    }

    h3 {
      margin: 6px 0 8px;
      font-size: 1.2rem;
      color: rgb(29, 29, 29);
    }

    p {
      margin: 0 0 6px;
      font-size: 1rem;
      color: rgb(117, 117, 117);
    }
  }

  @media screen and (max-width: 576px) {
    .cont_select_opt {
      width: 100%;
    }

    .select_opt_item {
      width: 100%;
      margin: 8px 0;
    }
  }
}

.footer {
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  color: rgb(80, 80, 80);
}

.cont_logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 250px;
    margin: 5px 0 15px;
  }

  p {
    width: 100%;
    text-align: center;
    color: rgb(149, 149, 149);
    margin-bottom: 30px;
    font-size: 0.8rem;
  }
}

.cont_titulo {
  position: relative;
  width: 100%;
  padding-top: 10px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 400px 70px;

  h2 {
    padding-top: 2px;
    grid-column: 1;
    grid-row: 1;
  }

  a {
    position: absolute;
    top: 2.9rem;
    left: 0;
    width: min-content;
    margin: 0;
    padding: 6px 18px 6px 12px;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9rem;
    white-space: nowrap;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 102, 0);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.1s;

    &:hover {
      transform: translate(2px, -1px);
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
    }

    &:active {
      transform: translate(-1px, 1px);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
        2px 2px 6px rgba(0, 0, 0, 0.3) inset;
    }

    svg {
      position: absolute;
      top: -2px;
      right: -6px;
      width: 32px;
      height: 32px;
    }
  }

  .filtro_empleado {
    display: flex;
    align-items: center;
    grid-column: 3;
    grid-row: 1;
    margin-left: 30px;

    svg {
      width: 38px;
      height: 38px;
      padding: 2px 4px;
      color: rgb(255, 255, 255);
      transition: all 0.1s;
      border-radius: 12px;
      cursor: pointer;

      &:hover {
        transform: translate(2px, -1px);
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
      }

      &:active {
        transform: translate(-1px, 1px);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
          2px 2px 6px rgba(0, 0, 0, 0.3) inset;
      }
    }
  }

  .search_bar {
    grid-column: 2;
    grid-row: 1;
  }

  &.only_searsh {
    grid-template-columns: 1fr 400px;
  }

  &.info_cierre {
    grid-template-columns: 1fr 250px;

    p {
      grid-column: 1;
      grid-row: 2;
      margin: 0;
      padding: 0 12px;
      font-size: 0.9rem;
      font-style: italic;
      color: rgb(150, 0, 0);
    }

    .cont_info_cierre {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        margin: 0;
        padding: 0;
        font-size: 1.1rem;
        color: rgb(29, 29, 29);
      }

      button {
        width: min-content;
        padding: 4px 18px;
        margin: 12px 0 0;
        white-space: nowrap;
      }
    }
  }

  &._in_list {
    display: block;
    width: 90%;
    padding: 0;
    margin: 0 auto 8px;

    .btn_reset_lista {
      position: static;
      display: block;
      margin-top: 8px;
    }
  }

  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 52px;

    .btn_lista {
      top: 94px;
    }

    .search_bar {
      margin-top: 6px;
    }
  }
}

.cont_tabla {
  width: 100%;
  padding: 0;
  border: solid 1px rgb(255, 102, 0);
  border-radius: 14px;
  overflow: hidden;
  scrollbar-color: rgb(160, 160, 160) rgba(0, 0, 0, 0);
  scrollbar-width: 10px;

  table {
    width: calc(100% + 3px);
    max-width: calc(100% + 3px);
    margin: -1px;
    border-collapse: collapse;

    th {
      position: relative;
      height: 35px;
      padding-right: 4px;
      border: solid 1px rgb(255, 102, 0);
      color: rgb(68, 68, 68);
      white-space: nowrap;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 4px;
        align-items: center;
        margin-bottom: -4px;
        cursor: pointer;

        &:active {
          transform: scale(0.8);
        }
      }

      .content {
        width: 100%;
        overflow: hidden;
      }

      .resize {
        position: absolute;
        top: 0;
        right: -5px;
        width: 10px;
        height: 100%;
        cursor: col-resize;
        z-index: 10;
      }
    }

    tbody {
      tr {
        background-color: rgb(255, 255, 255);

        td {
          padding: 4px 6px;
          font-size: 0.9rem;
          color: rgb(70, 70, 70);
          border: 1px solid rgb(255, 102, 0);
          overflow: hidden;
          cursor: pointer;

          p {
            margin: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &:first-child {
            padding-left: 12px;
          }

          &:last-child {
            border: none;
          }
        }

        &.remarcado {
          background-color: rgba(250, 210, 183, 0.5);
        }

        &.seleccionado {
          background-color: rgb(134, 132, 255) !important;

          td {
            color: rgb(255, 255, 255) !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    border-radius: 14px 14px 4px 4px;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(160, 160, 160);
    border-radius: 10px;
  }
}

.search_bar {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  border: 1px solid rgb(189, 189, 189);
  border-radius: 12px;
  overflow: hidden;

  input {
    width: 100%;
    height: auto;
    padding: 9px 48px 8px 12px;
    font-size: 1rem;
    font-weight: bold;
    color: rgb(70, 70, 70);
    background-color: rgb(255, 255, 255);
    border: none;

    &::placeholder {
      color: rgb(170, 170, 170);
    }
  }

  svg {
    position: absolute;
    top: -1px;
    right: -2px;
    width: 40px;
    height: 40px;
    padding: 1px 4px 4px;
    cursor: pointer;
    transition: all 0.1s;
    border-radius: 0 12px 12px 0;
    color: #f1843b;

    &:active {
      transform: translate(-1px, 1px);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset;
    }
  }
}

.back_shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 110;
  //   display: none;
}

.opc_filtro {
  position: absolute;
  top: 8px;
  right: 6px;
  width: 200px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(244, 164, 96);
  border-radius: 4px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  z-index: 111;

  .cnt_select {
    margin: 6px 0;
    border-bottom: 1px solid rgb(214, 214, 214);

    svg {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      color: rgb(255, 102, 0);
      margin-bottom: -4px;
    }
    select {
      width: 80%;
      border: none;
      background-color: rgb(255, 255, 255);
      margin-bottom: 4px;
      cursor: pointer;

      option {
        text-align: left;

        padding: 3px;
        cursor: pointer;

        &:target {
          background-color: rgba(250, 210, 183, 0.561);
        }
      }
    }
  }

  button {
    width: min-content;
    margin: 10px auto;
    padding: 6px 12px;
    font-size: 1rem;
    font-weight: bold;
  }

  .cerrar {
    h4 {
      padding: 8px 6px;
      margin-bottom: 16px;
    }

    svg {
      position: absolute;
      top: 4px;
      right: 0;
      width: 24px;
      height: 24px;
      float: right;
      margin-right: 4px;
      margin-bottom: 6px;
      color: rgb(255, 102, 0);
      transition: all 400ms;
      cursor: pointer;

      &:active {
        transform: scale(0.8);
      }
    }
  }
}

.popup_productos {
  position: fixed;
  top: 32px;
  width: 100%;
  max-width: 500px;
  max-height: calc(100vh - 60px);
  padding: 18px 0;
  display: block;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  overflow: hidden;
  z-index: 111;

  h2 {
    width: 100%;
    font-size: 1.2rem;
    color: rgb(60, 60, 60);
    text-align: center;
    padding: 0 18px 12px;
  }

  p {
    width: 100%;
    font-size: 0.9rem;
    text-align: center;
    color: rgb(100, 100, 100);
    font-weight: normal;
    padding: 0 12px 18px;
  }

  .cont_item_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: calc(100vh - 250px);
    padding: 12px 18px 12px 32px;
    margin-bottom: 12px;
    border-top: 1px solid rgb(180, 180, 180);
    border-bottom: 1px solid rgb(180, 180, 180);
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    label {
      width: 100%;
      margin-bottom: 10px;
      white-space: nowrap;
      cursor: pointer;

      input {
        cursor: pointer;
        margin-right: 8px;
      }

      span {
        font-size: 0.8rem;
        font-weight: bold;
        color: rgb(90, 90, 90);
      }
    }
  }
}

.botones {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    margin: 0 12px;
  }
}

.react-datepicker-popper {
  z-index: 12 !important;

  button {
    margin: 0 !important;

    border-radius: 6px;
    background: none !important;

    &:hover {
      transform: none;
      background: none !important;
      box-shadow: none !important;
    }
  }

  .react-datepicker__triangle {
    transform: translate(40px, 0px) !important;
  }
}

.linea_vertical {
  width: 1px;
  background-color: rgb(189, 189, 189);
  margin: 0 8px;
  border: none;
}

// para cotizaciones //
.grid_content {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .grid_item {
    position: relative;
    width: 100%;
    max-width: 480px;
    height: 150px;
    padding: 8px;
    margin: 12px 18px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.1s;

    &:hover {
      transform: translate(2px, -1px);
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3);

      ._button {
        opacity: 1;
        height: 100%;
      }
    }

    &:active {
      transform: translate(-1px, 1px);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
        2px 2px 6px rgba(0, 0, 0, 0.3) inset;
    }

    &._added_to_cart {
      transform: translate(2px, -1px);
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3);

      &:active {
        transform: translate(2px, -1px);
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3);
      }

      ._button {
        height: 100%;
        background-color: rgba(255, 102, 0, 0.4);
      }
    }

    ._button {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      margin: 0;
      padding: 0;
      background-color: rgba(255, 102, 0, 0.6);
      transition: all 0.1s;
      overflow: hidden;

      span {
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        margin: 0;
        padding: 12px 8px 18px;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        color: rgb(255, 255, 255);
        background-color: rgba(0, 0, 0, 0.6);

        svg {
          width: 32px;
          height: 32px;
          margin-left: 6px;
          color: rgb(255, 255, 255);
          margin-bottom: -8px;
        }

        samp {
          display: block;
          margin: 8px 0 0;

          i {
            font-size: 1.2rem;
            font-weight: bold;
            color: rgb(255, 255, 255);
            margin: 0 0 0 12px;

            svg {
              width: 18px;
              height: 18px;
              margin: 0 12px -2px;
              border: 1px solid rgb(255, 255, 255);
              border-radius: 4px;
              cursor: pointer;
              transition: all 0.1s;

              &:active {
                transform: translate(-2px, 2px);
              }
            }
          }
        }
      }
    }

    .grid_item_info {
      width: 100%;

      h2 {
        width: 100%;
        font-size: 1rem;
        text-align: center;
        padding: 0 12px;
        margin-bottom: 6px;
        color: rgb(255, 102, 0);
      }

      h4 {
        width: 100%;
        font-size: 0.8rem;
        padding: 4px 12px !important;
        margin-bottom: 6px;
        color: rgb(85, 85, 85);
      }

      p {
        display: block;
        width: 100%;
        padding: 4px 12px !important;
        text-align: left;
        font-size: 0.8rem;
        color: rgb(93, 93, 93);
        padding: 5px 0;
      }
    }

    .grid_item_image {
      width: 180px;
      min-width: 180px;

      img {
        width: 100%;
        height: 100px;
        border-radius: 6px;
        margin-bottom: 10px;
        object-fit: contain;
      }

      h5 {
        width: 100%;
        text-align: center;
        font-weight: bold !important;
        color: rgb(2, 133, 8);
      }
    }
  }
}

// para cuadre de caja //
._tabs_header {
  display: flex;
  flex-direction: row;

  ._tab {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin: 0 4px 0 0;
    border-radius: 6px 6px 0 0;
    background-color: rgb(230, 230, 230);
    border-top: 2px solid rgb(200, 200, 200);
    border-left: 2px solid rgb(200, 200, 200);
    border-right: 2px solid rgb(200, 200, 200);
    overflow: hidden;
    transition: all 0.1s;
    cursor: pointer;

    &:last-child {
      margin: 0;
    }

    &:hover {
      background-color: rgb(220, 220, 220);
    }

    &:active,
    &._active {
      background-color: rgb(200, 200, 200);
    }

    span {
      font-size: 1.1rem;
      font-weight: bold;
      white-space: nowrap;
      color: rgb(60, 60, 60);
    }
  }
}

._tabs_content {
  padding: 8px 6px 6px;
  border-radius: 0 0 12px 12px;
  background-color: rgb(200, 200, 200);
}

// para perfil //
.foto_perfil {
  width: 130px;
  height: 130px;
  padding: 4px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);

  svg {
    width: 100%;
    height: 100%;
  }
}

.wait_button {
  position: relative;
  width: 243px;
  margin: 30px auto 0;
  padding: 6px;
  border-radius: 6px;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  font-weight: bold;
  background-color: rgb(255, 102, 0);
  transition: all 0.1s;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @keyframes load {
    from {
      transform: rotate(0deg) scale(2);
    }
    to {
      transform: rotate(360deg) scale(2);
    }
  }

  .load__icon {
    width: 15px;
    height: 15px;
    margin: 4px;
    animation: linear load 2s infinite;
  }
}
