.alert {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.5);

  .alert__content {
    position: relative;
    min-width: 250px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 10px 10px;
    margin: 0 12px;
    border-radius: 12px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.9);
    background-color: rgb(255, 255, 255);

    .alert__icon {
      padding: 18px;
      display: flex;

      svg {
        margin: auto 0;
        fill: rgb(70, 70, 70);
      }
    }

    .input_icon {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      input {
        width: 160px;
        margin: 0;
        padding: 4px 8px 4px 34px;
        font-size: 0.9rem;

        &.to_mail {
          width: 250px;
        }

        @media screen and (max-width: 576px) {
          width: 170px;
          font-size: 1rem;

          &.to_mail {
            width: 100%;
          }
        }
      }

      svg {
        position: absolute;
        top: auto;
        left: 6px;
        width: 21px;
        height: 21px;
        color: rgb(255, 102, 0);

        &.btn_close {
          top: auto;
          left: auto;
          right: 6px;
          cursor: pointer;
        }
      }
    }

    label {
      display: inline-block;
      margin: 16px 8px 0;
      color: rgb(70, 70, 70);
      text-align: center;
      font-size: 0.9rem;

      @media screen and (max-width: 576px) {
        font-size: 1rem;
      }
    }

    p {
      position: relative;
      margin: 0 8px;
      color: rgb(70, 70, 70);
      text-align: center;
      font-size: 1rem;
      white-space: pre-wrap;

      @media screen and (max-width: 500px) {
        font-size: 1.1rem;
      }
    }

    .resartado {
      display: inline-block;
      margin: 12px 8px -8px;
      color: rgb(216, 15, 15);
      text-align: center;
      font-size: 1rem;
      font-weight: bold;

      @media screen and (max-width: 576px) {
        margin: 16px 8px -8px;
        font-size: 1.1rem;
      }
    }

    .close {
      color: rgb(70, 70, 70);
      font-size: 1rem;
      font-weight: bold;
      margin: 18px 0 0;
      padding: 4px 12px;
      text-decoration: none;
      cursor: pointer;

      &:focus,
      &:active,
      &:active:focus {
        outline: none;
      }

      @media screen and (max-width: 500px) {
        font-size: 1.1rem;
      }
    }

    .in_row {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;

      .close {
        display: block;
        width: 100%;
        margin: 18px 4px 0;
        text-align: center;
      }
    }

    &.alert-success {
      background-color: rgb(76, 225, 182);
      border-color: #4ce1b6;

      &.alert--bordered .alert__icon {
        background-color: #4ce1b6;
      }

      &.alert--colored .alert__icon {
        background-color: #55e5bb;
      }

      &.alert--neutral .alert__icon svg {
        fill: #4ce1b6;
      }
    }

    &.alert-warning {
      background-color: #f6da6e;
      border-color: #f6da6e;

      &.alert--bordered .alert__icon {
        background-color: #f6da6e;
      }

      &.alert--colored .alert__icon {
        background-color: #f4dd87;
      }

      &.alert--neutral .alert__icon svg {
        fill: #f6da6e;
      }
    }

    &.alert-danger {
      background-color: #ff4861;
      border-color: #ff4861;

      &.alert--bordered .alert__icon {
        background-color: #ff4861;
      }

      &.alert--colored .alert__icon {
        background-color: #f88e7d;
      }

      &.alert--neutral .alert__icon svg {
        fill: #ff4861;
      }
    }

    &.alert--bordered {
      background-color: transparent;

      p {
        color: #646777;
      }

      .close {
        color: #999999;
      }
    }

    &.alert--neutral {
      background-color: transparent;
      border-color: #f2f4f7;

      p {
        color: #646777;
      }

      .close {
        color: #999999;
      }

      svg {
        fill: #646777;
      }

      // .alert__icon {

      // }
    }
  }
}

.alert__input {
  display: block;
  position: absolute;
  left: 0;
  top: 36px;
  width: min-content;
  padding: 4px 12px;
  margin-top: 4px;
  margin-right: 18px;
  font-size: 0.7rem;
  border-radius: 8px;
  white-space: nowrap;
  color: rgb(240, 240, 240);
  z-index: 10;
  background-color: #f83a3a;

  &._up {
    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      margin-top: -10px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #f83a3a;
    }
  }

  &._down {
    top: -32px;
    left: 0;
    z-index: 90;

    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      margin-top: 16px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #f83a3a;
    }
  }

  &._area {
    top: 106px;
  }
}
