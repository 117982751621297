.form_principal {
    .mensaje {
        width: 100%;

        p {
            width: 100%;
            color: rgb(149, 149, 149);
            margin: 20px 0;
            font-size: 0.9rem;
        }
    }
    button{
        margin-top: 30px;

    }
}
