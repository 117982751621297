.cont_home {
  width: 100%;
  display: grid;
  grid-template-columns: 75% 25%;

  .cards_vertical {
    display: block;
    grid-column: 2;
    grid-row: 1;

    .card {
      width: 100%;
      max-width: 250px;

      margin: 0 0 32px 0;
      display: grid;
      background: linear-gradient(-160deg, #f1843b, rgb(255, 255, 255) 70%);

      h2 {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0 0 8px 0;
        grid-column: 1 / 3;
        grid-row: 1;
      }

      .logo_card {
        position: absolute;
        bottom: -6px;
        right: 6px;
        width: 38px;
        height: 38px;
        margin: 0 0 8px 0;
      }

      div.recharts-wrapper {
        .recharts-legend-wrapper {
          position: absolute;
          bottom: 0 !important;
        }
      }
    }
  }

  .card {
    position: relative;
    margin: 0 auto;
    padding: 16px 12px;
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    overflow: hidden;

    &.big {
      width: 90%;
      grid-column: 1;
      grid-row: 1;
      margin-bottom: 22px;

      h2 {
        margin: 0 12px 0 12px;
        font-size: 1.2rem;
        font-weight: bold;
        grid-column: 1 / 3;
        grid-row: 1;
      }

      .recharts-wrapper tspan {
        font-size: 0.7rem;
      }

      .recharts-wrapper span {
        font-size: 0.8rem;
      }

      .grafico_ventas {
        position: relative;
        min-height: 280px;
        padding-top: 18px;
        padding-bottom: 36px;

        .cont_barras {
          width: 100%;
          margin-bottom: 16px;

          h4 {
            font-size: 1.1rem;
            font-weight: bold;
            margin: 0 0 18px;
            padding: 0 16px;

            &.titulo_msg {
              font-size: 1.5rem;
              font-weight: bold;
              margin: 62px 0 0;
              padding: 0 16px;
            }
          }

          p {
            position: relative;
            display: block;
            margin: 8px 0;
            padding: 6px 18px 6px 12px;
            font-size: 0.9rem;
            color: rgb(255, 255, 255);
            background-color: rgb(136, 132, 216);
            transition: all 0.1s;
            overflow: hidden;
            white-space: nowrap;
            border-radius: 6px;
            cursor: pointer;

            &:hover {
              transform: translate(2px, -1px);
              box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.3);
            }

            &:active {
              transform: translate(-1px, 1px);
              box-shadow: none;
            }

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }

            span {
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgb(0, 150, 57);
              // border-right: 2px solid darken(rgb(0, 150, 57), 10%);
              z-index: 1;
            }

            samp {
              float: right;
              font-weight: normal;
            }

            i {
              position: relative;
              display: block;
              margin: 0;
              padding: 0;
              font-weight: bold;
              z-index: 2;
            }
          }
        }

        .custom_tooltip {
          padding: 8px 12px;
          border: 1px solid rgb(200, 200, 200);
          background-color: rgb(255, 255, 255);
          border-radius: 6px;

          .tooltip_title {
            font-size: 0.9rem;
            font-weight: bold;
            margin: 0 0 8px;
          }

          .tooltip_label {
            font-size: 0.8rem;
            margin: 0 0 4px;
          }
        }
      }

      .card_buttons_group {
        position: relative;
        width: 100%;
        margin: -38px 0 0;

        .card_button_option {
          width: min-content;
          margin: 0 12px;
          padding: 2px 12px 6px 12px;
          white-space: nowrap;
          font-size: 0.9rem;
          color: rgb(70, 70, 70);
          background-color: rgb(255, 255, 255);
          border: 1px solid rgb(70, 70, 70);

          svg {
            width: 22px;
            height: 22px;
            margin: 0 0 -4px;
          }
        }
      }
    }

    .toggle-btn {
      position: absolute;
      bottom: 8px;
      right: 12px;
      display: inline-flex;
      justify-content: flex-start;
      font-size: 0.9rem;
      padding: 4px 0;
      margin-bottom: 0;
      cursor: pointer;
      z-index: 6;

      .toggle-text-left {
        color: #999;
        pointer-events: none;
      }

      .toggle-text-right {
        color: #999;
        pointer-events: none;
      }

      .toggle-btn__input-label {
        cursor: pointer;
        text-indent: -9999px;
        width: 40px;
        height: 15px;
        display: block;
        border-radius: 23px;
        position: relative;
        margin: 3px 8px 0;

        border: none;
        background-color: rgba(170, 170, 170, 0.3);

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;

          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid rgb(189, 189, 189);
          transition: 0.3s;
          box-shadow: 0 5px 10px rgba(153, 153, 153, 0.4);
          background-color: rgb(255, 255, 255);
        }
      }

      .toggle-btn__input {
        height: 0;
        width: 0;
        visibility: hidden;
      }

      .toggle-btn__input:checked ~ .toggle-btn__input-label {
        &:after {
          background: rgb(0, 202, 145);
          border: 1px solid rgb(0, 202, 145);
          left: 100%;
          transform: translateX(-100%);
        }
      }
    }

    .radio_group {
      display: inline-flex;
      position: absolute;
      bottom: 8px;
      right: 12px;
      padding: 4px 0;
      z-index: 6;

      label {
        display: block;
        margin: 8px 0 0 16px;
        padding: 0;
        cursor: pointer;
        font-size: 0.9rem;
        color: rgb(70, 70, 70);

        span {
          display: inline-block;
          margin: 0 0 0 8px;
          padding: 0;
        }
      }
    }
  }

  .cont_btns {
    display: flex;
    flex-direction: column;

    a {
      position: relative;
      width: min-content;
      max-width: 130px;
      margin: 8px 0 0;
      padding: 6px 18px 6px 12px;
      color: rgb(70, 70, 70);
      text-decoration: none;
      font-weight: bold;
      font-size: 0.9rem;
      white-space: nowrap;
      background-color: rgb(255, 255, 255);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
      border-radius: 12px;
      border: 1px solid #464646;
      cursor: pointer;
      transition: all 0.1s;

      &:hover {
        transform: translate(2px, -1px);
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
      }

      &:active {
        transform: translate(-1px, 1px);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
          2px 2px 6px rgba(0, 0, 0, 0.3) inset;
      }

      svg {
        position: absolute;
        top: -2px;
        right: -6px;
        width: 32px;
        height: 32px;
      }
    }
  }

  .cont_info {
    grid-column: 2;
    grid-row: 1 / 3;

    h1 {
      font-size: 2.5rem;
      font-weight: bold;
      margin: 0 0 8px 0;
      text-align: center;
    }
  }

  .cont_chart {
    width: min-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .label_legend {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: max-content;
      max-width: 180px;
      height: min-content;
      padding: 8px 12px;
      border-radius: 12px;

      p {
        display: inline;
        vertical-align: middle;
        text-align: left;
        font-size: 0.9rem;
        margin: 3px 0;
      }

      svg {
        width: 0.9rem;
        height: 0.9rem;
        margin: 0 0 -0.2rem 0;
      }
    }

    .chart_circle {
      width: 100%;
      max-width: 300px;
      height: 300px;
    }
  }

  .search_bar {
    max-width: 450px;
  }

  .cierre_de_caja {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 12px;

    button {
      position: absolute;
      top: 0;
      right: 2px;
      display: block;
      width: min-content;
      margin: 12px 6px 0;
      padding: 8px 16px;
      font-weight: bold;
      font-size: 0.9rem;
      white-space: nowrap;
      border-radius: 12px;
      color: rgb(255, 255, 255);
      background-color: rgb(255, 102, 0);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
      transition: all 0.1s;
      cursor: pointer;
      border: none;

      &:hover {
        transform: translate(2px, -1px);
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
      }

      &:active {
        transform: translate(-1px, 1px);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
          2px 2px 6px rgba(0, 0, 0, 0.3) inset;
      }

      &.a_link {
        position: relative;
        display: block;
        margin: 12px 6px 0;
        padding: 8px 20px 8px 12px;
        font-weight: bold;
        font-size: 0.9rem;
        border-radius: 12px;
        color: rgb(70, 70, 70);
        background-color: rgb(255, 255, 255);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        transition: all 0.1s;
        cursor: pointer;
        border: 1px solid #464646;
        text-decoration: none;

        &:hover {
          transform: translate(2px, -1px);
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
        }

        &:active {
          transform: translate(-1px, 1px);
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
            2px 2px 6px rgba(0, 0, 0, 0.3) inset;
        }

        svg {
          position: absolute;
          top: 0;
          right: -6px;
          width: 32px;
          height: 32px;
        }
      }
    }

    a {
      position: relative;
      display: block;
      margin: 12px 6px 0;
      padding: 8px 20px 8px 12px;
      font-weight: bold;
      font-size: 0.9rem;
      border-radius: 12px;
      color: rgb(70, 70, 70);
      background-color: rgb(255, 255, 255);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
      transition: all 0.1s;
      cursor: pointer;
      border: 1px solid #464646;
      text-decoration: none;

      &:hover {
        transform: translate(2px, -1px);
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
      }

      &:active {
        transform: translate(-1px, 1px);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
          2px 2px 6px rgba(0, 0, 0, 0.3) inset;
      }

      svg {
        position: absolute;
        top: 0;
        right: -6px;
        width: 32px;
        height: 32px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;

    .card.big {
      width: 100%;

      .card_buttons_group {
        margin: 18px 0 0;
      }
    }

    .cierre_de_caja {
      display: flex;
      flex-direction: column;

      button {
        width: 160px;
        position: relative;
        top: 0;
        right: auto;
      }

      a {
        width: 160px;
      }
    }
  }
}

.sidebar {
  .wrapper .cont_chart .chart_circle {
    margin-left: 0;
  }

  &.sidebar--show {
    .wrapper .cont_chart .chart_circle {
      margin-left: -18px;
    }
  }
}
