.content_modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.win_modal {
  position: absolute;
  display: block;
  max-width: 550px;
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.6);
  background-color: rgb(255, 255, 255);
  z-index: 91;
  cursor: auto;

  h4 {
    margin-bottom: 28px;
    padding: 0;
    font-size: 16px;

    svg {
      width: 26px;
      float: right;
      cursor: pointer;
      margin-left: 30px;
    }

    span {
      font-size: 14px;
    }

    &._error {
      width: 100%;
      margin: 16px 6px -12px;
      padding-bottom: 0;
      padding-right: 35px;
      font-size: 14px;
      text-align: right;
      color: rgb(253, 42, 42);

      svg {
        position: absolute;
        right: 0;
        width: 20px;
        margin-left: -35px;
        margin-right: 12px;
        color: rgb(255, 32, 32);
        cursor: auto;
      }
    }

    &._loadind {
      margin: 22px 12px 22px 8px;
      padding: 0;
      background-color: transparent;
      border: none;
      color: rgb(70, 70, 70);

      svg {
        width: 24px;
        height: 24px;
        float: none;
        margin: 0 12px -4px 0;
        animation: linear load 2s infinite;
        cursor: auto;
      }

      @keyframes load {
        from {
          transform: rotate(0deg) scale(1.2);
        }
        to {
          transform: rotate(360deg) scale(1.2);
        }
      }
    }
  }

  &._impress {
    // padding: 0;
    // position: relative;
    max-width: 816px;
    // max-width: 21.6cm;
    // max-height: 27.9cm;
    // border-radius: 0;
    // box-shadow: none;

    .impress_label {
      position: relative;
      width: 100%;
      min-height: 1050px;
      display: block;
      padding-top: 18px;

      p {
        font-size: 0.9rem;
      }

      ._header {
        display: grid;
        grid-template-columns: 1fr 1fr 200px;
        grid-template-rows: 1fr 80px;
        align-items: flex-start;

        img {
          width: 200px;
          height: 100px;
          object-fit: contain;
          grid-column: 1;
          grid-row: 1;
        }

        ._header_info {
          width: 100%;
          margin-top: 8px;
          grid-column: 2;
          grid-row: 1;
        }

        svg {
          grid-column: 3;
          grid-row: 1;
        }

        ._header_num {
          grid-column: 3;
          grid-row: 2;
          width: 100%;
          border: none;
          margin-top: 12px;
          padding-top: 18px;

          h2 {
            text-align: center;
            font-size: 1.2rem;
          }
        }
      }

      ._body {
        margin-top: -78px;
        padding: 0 12px;

        ._body_info {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr 1fr;

          ._body_info_user {
            grid-column: 1;
            grid-row: 2;
            width: 100%;
            margin-top: 18px;
          }

          ._body_info_date {
            grid-column: 1;
            grid-row: 1;
          }
        }

        ._body_items {
          width: 100%;
          margin: 28px 0 0;
          border-collapse: collapse;

          thead {
            background-color: rgb(210, 210, 210);

            th {
              position: relative;
              padding: 4px 6px;
              border: solid 1px rgb(170, 170, 170);
              font-size: 0.8rem;
              color: rgb(68, 68, 68);
              white-space: nowrap;

              &:nth-child(2) {
                min-width: 100px;
              }
            }
          }

          tbody {
            tr {
              background-color: rgb(255, 255, 255);

              td {
                padding: 4px 6px;
                font-size: 0.8rem;
                color: rgb(70, 70, 70);
                border: 1px solid rgb(170, 170, 170);

                &:last-child {
                  border: 1px solid rgb(170, 170, 170);
                }
              }
            }
          }
        }
      }

      ._valores {
        padding: 18px 12px 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        p {
          margin: 0;
          padding: 0;
          font-size: 0.9rem;

          b {
            font-size: 1rem;
          }
        }

        h4 {
          width: 300px;
          max-width: 300px;
          font-size: 1rem;
          font-weight: bold;
          text-align: right;
          background-color: rgb(210, 210, 210);
          border: 2px solid rgb(170, 170, 170);
          white-space: nowrap;
          overflow: hidden;

          hr {
            margin: 0;
            padding: 0;
            border: none;
            border-top: 2px solid rgb(170, 170, 170);
          }

          b {
            display: inline-block;
            width: 90px;
            padding-left: 12px;
            text-align: left;
          }

          span {
            display: inline-block;
            width: 200px;
            margin: 0 0 0 8px;
            padding: 6px 18px 4px 32px;
            font-weight: normal;
            text-align: left;
            border-left: 2px solid rgb(170, 170, 170);
            background-color: rgb(255, 255, 255);
          }

          samp {
            display: block;
            width: 100%;
            padding: 4px 6px;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: normal;
            font-size: 0.9rem;
            text-align: center;
            border-top: 2px solid rgb(170, 170, 170);
            border-bottom: 2px solid rgb(170, 170, 170);
            background-color: rgb(255, 255, 255);

            &._last {
              border-bottom: none;
            }
          }
        }
      }

      ._anexo {
        h3 {
          font-size: 1rem;
          font-weight: bold;
          color: rgb(255, 136, 38);
        }

        p {
          font-size: 0.9rem;
          white-space: pre-line;
        }
      }

      ._footer {
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        width: 100%;

        p {
          width: 100%;
          text-align: center;
          font-size: 0.7rem;
        }
      }
    }
  }
}
