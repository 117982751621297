header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  border-bottom: 1px solid rgb(54, 54, 54);
  background: linear-gradient(45deg, #f1843b, rgb(6, 12, 23) 80%);
  z-index: 100;

  .logo {
    position: absolute;
    top: 6px;
    left: 0;
    padding: 0 12px;

    img {
      width: 100px;
      height: auto;
    }
  }

  .basket_data {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    cursor: pointer;

    svg {
      width: 32px;
      height: 32px;
      color: rgb(255, 255, 255);
    }

    span {
      position: absolute;
      display: inline-flex;
      top: 2px;
      right: -8px;
      width: 18px;
      height: 18px;
      padding: 0;
      margin: 0;
      font-size: 0.7rem;
      font-weight: bold;
      align-items: center;
      justify-content: center;
      color: rgb(255, 255, 255);
      background-color: rgb(241, 132, 59);
      border-radius: 10px;
    }
  }

  .user_data {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 22px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    z-index: 91;

    svg {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }

    span {
      font-size: 0.9rem;
      font-weight: bold;
      color: rgb(255, 255, 255);
    }

    ul {
      position: absolute;
      top: 46px;
      right: 12px;
      display: block;
      list-style: none;
      padding: 8px 0;
      background: linear-gradient(0deg, #f1843b, rgb(6, 12, 23) 80%);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
      border-radius: 0 0 8px 8px;
      overflow: hidden;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 6px 12px;
        font-size: 0.9rem;
        color: rgb(255, 255, 255);
        cursor: pointer;

        &:hover {
          color: rgb(49, 49, 49);
          background: rgba(255, 255, 255, 0.8);
        }

        svg {
          width: 18px;
          height: 18px;
          margin-right: 12px;
          margin-bottom: -4px;
        }
      }
    }

    .bkg_menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      cursor: auto;
    }
  }

  nav {
    position: fixed;
    top: 80px;
    left: 12px;
    display: block;
    min-height: 400px;
    background-color: rgb(255, 255, 255);
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.3);
    transition: all 0.5s;
    border-radius: 8px;
    overflow: hidden;
    z-index: 90;

    .toggle_menu {
      position: absolute;
      top: 4px;
      right: 0;
      width: 32px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: right;
      // border: 1px solid rgb(54, 54, 54);
      border-radius: 4px;
      cursor: pointer;

      svg {
        width: 26px;
        height: 26px;
        padding: 0;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin-top: 22px;
      overflow: hidden;
      transition: all 0.5s;

      li {
        display: block;

        a {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          text-decoration: none;
          color: rgb(53, 53, 53);
          font-size: 1rem;
          font-weight: bold;
          cursor: pointer;
          transition: all 0.5s;

          svg {
            transition: all 0.5s;
          }

          span {
            transition: all 0.5s;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    @media screen and (max-width: 480px) {
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70px;
      min-height: 70px;

      .toggle_menu {
        display: none;
      }

      ul {
        width: 100%;
        height: 80px;
        display: flex;
        flex-direction: row;
        margin-top: 0;
        overflow-y: hidden;
        overflow-x: scroll;
      }
    }
  }
}

.sidebar {
  width: 100%;

  nav {
    padding: 8px 0;

    ul {
      li {
        a {
          width: 40px;
          height: 40px;
          margin: 8px 6px;
          padding: 2px 4px;
          border-radius: 12px;

          &:hover {
            transform: translate(-2px, -1px);
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
            transition: all 0.1s;
          }

          &:active,
          &.active {
            transform: translate(-1px, 1px);
            // background-color: #f1843b;
            // color: rgb(255, 255, 255);
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
              2px 2px 6px rgba(0, 0, 0, 0.3) inset;
            transition: all 0.1s;
          }

          svg {
            width: 40px;
            height: 40px;
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  &.sidebar--show {
    nav {
      width: 170px;

      ul {
        li {
          a {
            width: calc(100% - 14px);
            margin: 8px 6px;
            padding: 8px 6px 8px 6px;
            box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);

            svg {
              display: block;
              width: 26px;
              min-width: 26px;
              height: 26px;
            }

            span {
              display: block;
              padding-left: 12px;
            }

            &:hover {
              transform: translate(-2px, -1px);
              box-shadow: 6px 6px 10px 2px rgba(0, 0, 0, 0.4);
              transition: all 0.1s;
            }

            &:active,
            &.active {
              transform: translate(-1px, 1px);
              // background-color: #f1843b;
              // color: rgb(255, 255, 255);
              box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) inset,
                2px 2px 6px rgba(0, 0, 0, 0.3) inset;
              transition: all 0.1s;
            }
          }
        }
      }
    }
  }
}

.row_menu {
  width: 0 !important;
  max-width: 0 !important;
  padding: 0 !important;

  .back_form {
    position: fixed !important;
    width: 100vw !important;
    height: 100vh !important;
    top: 0;
    left: 0;
    z-index: 90;
    background: transparent;
    cursor: auto;
  }
}

.menu {
  position: absolute;
  display: block;
  max-width: 220px;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.6);
  z-index: 91;

  ul {
    margin: 12px 0;
    padding: 0;
    list-style: none;

    hr {
      width: 100%;
      height: 1px;
      margin: 8px 0 4px;
      opacity: 0.5;
      background-color: rgb(54, 54, 54);
    }

    ._legend {
      display: block;
      width: min-content;
      margin: -11px 0 4px 50%;
      padding: 0 6px;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      line-height: 1;
      color: rgb(54, 54, 54);
      background-color: rgb(255, 255, 255);
      transform: translateX(-50%);
    }

    li {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: 0;
      padding: 4px 12px;
      white-space: nowrap;
      cursor: pointer;

      svg {
        width: 20px;
        min-width: 20px;
        color: rgb(54, 54, 54);
      }

      span {
        margin-left: 8px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis !important;
        color: rgb(54, 54, 54);
      }

      &:hover {
        background-color: rgb(134, 132, 255);

        svg,
        span {
          color: rgb(255, 255, 255);
        }
      }
    }
  }
}
