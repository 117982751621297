* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

body {
  font-size: 1rem;
  font-family: Gravity, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: linear-gradient(0deg, #f1843b, rgb(6, 12, 23) 80%);
  background-color: rgb(230, 230, 230);
  color: rgb(30, 30, 30);

  &::-webkit-scrollbar {
    display: none;
  }
}

.wrapper {
  display: block;
  width: 100%;
  height: 100vh;
  padding: 80px 16px 0 16px;
  transition: all 0.5s;

  .cont_principal {
    display: block;
    width: 95%;
    margin: 0 auto 18px;
    padding: 16px 12px;
    background-color: rgb(255, 255, 255);
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    overflow: hidden;

    &.deg_top {
      background: linear-gradient(-160deg, #f1843b, rgb(255, 255, 255) 40%),
        rgb(255, 255, 255);
      background-size: 100% 600px;
      background-repeat: no-repeat;
    }

    &.cont_forms {
      width: 50%;

      @media screen and (max-width: 820px) {
        width: 80%;
      }

      @media screen and (max-width: 680px) {
        width: 100%;
      }
    }

    &.cont_forms_20 {
      width: 20%;
    }

    &.cont_select {
      width: min-content;
    }
  }

  @media screen and (max-width: 680px) {
    padding: 80px 6px 0;

    .cont_principal {
      width: 100%;

      &.cont_select {
        width: 100%;
      }
    }

  }
}

.sidebar {
  padding-left: 50px;

  &.sidebar--show {
    display: block;

    .wrapper {
      padding-left: 140px;

      @media screen and (max-width: 480px) {
        padding-left: 0;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding-left: 0;
  }
}

.in_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .load_icon {
    width: 250px;
    height: 250px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.footer {
  height: 60px;
}
