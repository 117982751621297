._header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px;

  h2 {
    margin: 8px 0 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }

  h3 {
    margin: 0 0 12px;
    padding: 0;
    font-size: 1.1rem;
    font-weight: bold;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    line-height: 1.3;
    color: rgb(70, 70, 70);
  }

  img {
    width: 25%;
    max-width: 400px;
    height: 150px;
    object-fit: contain;
  }

  ._header_info {
    width: 25%;
    display: block;
    margin: 0 18px;
  }

  svg {
    margin: 0 18px;
  }

  ._header_num {
    width: max-content;
    height: min-content;
    padding: 8px 16px;
    border: 1px solid rgb(170, 170, 170);
    border-radius: 6px;

    p {
      text-align: center;
      white-space: pre;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    img {
      width: 100%;
      height: auto;
    }

    ._header_info {
      width: 100%;
      margin: 24px 0 8px;
      text-align: center;
    }

    svg {
      margin: 18px 12px;
    }
  }
}

._body {
  display: block;

  ._row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ._info_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgb(170, 170, 170);
    border-right: 1px solid rgb(170, 170, 170);

    h3 {
      width: 110px;
      min-width: 110px;
      margin: 0;
      padding: 4px 6px;
      font-size: 0.9rem;
      font-weight: bold;
      background-color: rgb(210, 210, 210);
      border-left: 1px solid rgb(170, 170, 170);
      border-right: 1px solid rgb(170, 170, 170);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      width: 100%;
      margin: 0;
      padding: 0 12px;
      font-size: 0.9rem;
      line-height: 1.3rem;
      color: rgb(70, 70, 70);
    }
  }

  ._info_column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  ._body_info {
    width: 100%;
    display: block;
    margin: 16px 0 12px;

    ._body_info_user {
      width: calc(100% - 450px);
      display: flex;
      flex-direction: column;
      align-items: center;

      ._info_row:nth-child(1) {
        border-top: 1px solid rgb(170, 170, 170);
      }
    }

    ._body_info_date {
      display: block;
      width: 400px;
      float: right;

      h2 {
        width: 100%;
        margin: 0;
        padding: 4px 6px;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        background-color: rgb(210, 210, 210);
        border: 1px solid rgb(170, 170, 170);
      }

      ._info_row {
        h3 {
          width: 200px;
        }
      }

      @media screen and (max-width: 1100px) {
        width: 330px;
        float: none;
      }
    }

    @media screen and (max-width: 1100px) {
      flex-direction: column;

      ._body_info_user {
        width: 100%;
        margin: 18px 0 0;        
      }
    }
  }

  ._body_items {
    width: 100%;
    margin: 28px 0 0;
    border-collapse: collapse;

    thead {
      background-color: rgb(210, 210, 210);

      th {
        position: relative;
        padding: 4px 6px;
        border: solid 1px rgb(170, 170, 170);
        font-size: 0.9rem;
        color: rgb(68, 68, 68);
        white-space: nowrap;

        &:nth-child(2) {
          min-width: 200px;
        }
      }
    }

    tbody {
      tr {
        background-color: rgb(255, 255, 255);

        td {
          padding: 4px 6px;
          font-size: 0.9rem;
          color: rgb(70, 70, 70);
          border: 1px solid rgb(170, 170, 170);
        }
      }
    }
  }
}

._valores {
  padding: 18px 12px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  p {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;

    b {
      font-size: 1rem;
    }
  }

  h4 {
    width: 300px;
    max-width: 300px;
    font-size: 1rem;
    font-weight: bold;
    text-align: right;
    background-color: rgb(210, 210, 210);
    border: 1px solid rgb(170, 170, 170);
    white-space: nowrap;
    overflow: hidden;

    hr {
      margin: 0;
      padding: 0;
      border: none;
      border-top: 1px solid rgb(170, 170, 170);
    }

    b {
      display: inline-block;
      width: 90px;
      padding-left: 12px;
      text-align: left;
    }

    span {
      display: inline-block;
      width: 200px;
      margin: 0 0 0 8px;
      padding: 6px 18px 4px 32px;
      font-weight: normal;
      text-align: left;
      border-left: 1px solid rgb(170, 170, 170);
      background-color: rgb(255, 255, 255);
    }

    samp {
      display: block;
      width: 100%;
      padding: 4px 6px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-size: 0.9rem;
      text-align: center;
      border-top: 1px solid rgb(170, 170, 170);
      border-bottom: 1px solid rgb(170, 170, 170);
      background-color: rgb(255, 255, 255);

      &._last {
        border-bottom: none;
      }
    }
  }
}

._anexo {
  width: 100%;
  display: block;
  margin: 0;
  padding: 8px 28px 32px;

  h3 {
    padding: 6px 0;
    margin: 32px 0 4px;
    font-size: 1.2rem;
    font-weight: bold;
    color: rgb(255, 136, 38);
  }

  p {
    margin: 0;
    padding: 0 0 12px;
    font-size: 1rem;
    line-height: 1.5;
    white-space: pre-line;

    &._option {
      width: 100%;
      display: block;
      margin: 0 0 8px;
      padding: 0 0 0 18px;
      font-size: 0.9rem;
      line-height: 1;

      svg {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        margin-bottom: -4px;
      }
    }
  }

  .img_template {
    width: 90%;
    margin-left: 18px;
  }

  .cont_img_template {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .img_template {
      width: 40%;
    }

    p {
      margin-left: 32px;
      margin-right: 18px;
    }
  }
}

._footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0 0;

  p {
    display: inline;
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    color: rgb(70, 70, 70);
  }
}
